import React, { FC } from 'react'
import { Center, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { useDispatch, useSelector } from 'react-redux'
import {
  IconApps,
  IconBellRinging,
  IconCashBanknote,
  IconCertificate,
  IconDatabase,
  IconDotsCircleHorizontal,
  IconFileStack,
  IconFileText,
  IconHandFinger,
  IconHierarchy,
  IconScanEye,
  IconTableShortcut,
  IconUserCircle,
  IconUserPlus,
  IconUsers,
} from '@tabler/icons-react'
import { motion } from 'framer-motion'
import useAuth from '@/Hooks/useAuth'
import IconMessage from '@/Icons/IconMessage'
import { router, usePage } from '@inertiajs/react'
import { RootState } from '@/Store'
import { Badge, Box } from '@mantine/core'
import { toggleSubSidebar } from '@/Store/globalSlice'

const SubSidebar: FC = () => {
  const page = usePage()
  const auth = useAuth()
  const dispatch = useDispatch()
  const globalStore = useSelector((state: RootState) => state.global)

  const tabItems = []

  if (auth.role.permissions.includes('settings_module_edit_own_profile')) {
    tabItems.push({
      label: 'Personal Details',
      route: '/settings',
      icon: <IconUserCircle width="20" height="20" color={XelaColor.Blue5} />,
    })
  }

  if (
    auth.role.permissions.includes('settings_module_edit_agency_profile') ||
    auth.role.permissions.includes('settings_module_edit_branches')
  ) {
    tabItems.push({
      label: 'Agency Details',
      route: '/settings/agency-details',
      icon: <IconDatabase width="20" height="20" color={XelaColor.Blue5} />,
    })
  }

  if (
    auth.agency.subscription_features.includes('roles_and_permissions') &&
    auth.role.permissions.includes('settings_module_access_roles_permissions')
  ) {
    tabItems.push({
      label: 'Roles & Permissions',
      route: '/settings/roles-permissions',
      icon: <IconUserPlus width="20" height="20" color={XelaColor.Blue5} />,
    })
  }

  if (auth.role.permissions.includes('settings_module_access_users')) {
    tabItems.push({
      label: 'Team Management',
      route: '/settings/team',
      icon: <IconUsers width="20" height="20" color={XelaColor.Blue5} />,
    })
  }

  if (
    auth.agency.subscription_features.includes('meta') &&
    auth.role.permissions.includes('settings_module_add_meta_option')
  ) {
    tabItems.push({
      label: 'Customise',
      route: '/settings/options',
      icon: <IconDotsCircleHorizontal width="20" height="20" color={XelaColor.Blue5} />,
    })
  }

  if (auth.role.permissions.includes('settings_module_access_entity_forms')) {
    tabItems.push({
      label: 'Lead Forms',
      route: '/settings/entity-forms',
      icon: <IconHandFinger width="20" height="20" color={XelaColor.Blue5} />,
    })
  }

  if (
    auth.agency.subscription_features.includes('meta') &&
    auth.role.permissions.includes('course_wizard_access_course_wizard')
  ) {
    tabItems.push({
      label: 'Courses',
      route: '/settings/courses',
      icon: <IconCertificate width="20" height="20" color={XelaColor.Blue5} />,
    })
  }

  if (auth.role.permissions.includes('settings_module_lead_form_settings')) {
    tabItems.push({
      label: 'QR Forms',
      route: '/settings/lead-forms',
      icon: <IconFileText width="20" height="20" color={XelaColor.Blue5} />,
    })
  }

  if (auth.role.permissions.includes('settings_module_lead_form_settings')) {
    tabItems.push({
      label: 'Migrate Leads',
      route: '/settings/migrate-leads',
      icon: <IconFileStack width="20" height="20" color={XelaColor.Blue5} />,
    })
  }

  if (auth.role.permissions.includes('settings_module_notification_management')) {
    tabItems.push({
      label: 'Notifications Management',
      route: '/settings/notification-settings',
      icon: <IconBellRinging width="20" height="20" color={XelaColor.Blue5} />,
    })
  }

  if (auth.agency.subscription_features.includes('fb_integrations')) {
    if (auth.role.permissions.includes('settings_module_access_integrations')) {
      tabItems.push({
        label: 'Integrations',
        route: '/settings/integrations',
        icon: <IconApps width="20" height="20" color={XelaColor.Blue5} />,
        badge: 'beta',
      })
    }

    if (auth.role.permissions.includes('settings_module_access_email_templates')) {
      tabItems.push({
        label: 'Communications',
        route: '/settings/communication',
        icon: <IconMessage width="20" height="20" color={XelaColor.Blue5} />,
        badge: 'beta',
      })
    }
  }

  if (
    auth.agency.subscription_features.includes('payments') &&
    auth.role.permissions.includes('settings_module_access_payments')
  ) {
    tabItems.push({
      label: 'Payments',
      route: '/settings/payments',
      icon: <IconCashBanknote width="20" height="20" color={XelaColor.Blue5} />,
    })
  }

  if (auth.role.permissions.includes('settings_module_website_integration')) {
    tabItems.push({
      label: 'Website Integration',
      route: '/settings/website-integration',
      icon: <IconHierarchy width="20" height="20" color={XelaColor.Blue5} />,
    })
  }

  if (
    auth.role.permissions.includes('settings_module_access_workflows') &&
    (auth.user.is_system_user || auth.user.email === 'boparaiamrit@gmail.com')
  ) {
    tabItems.push({
      label: 'Workflows',
      route: '/settings/workflows',
      icon: <IconHierarchy width="20" height="20" color={XelaColor.Blue5} />,
      badge: 'beta',
    })
  }

  if (auth.role.permissions.includes('settings_module_access_automations')) {
    tabItems.push({
      label: 'Automation',
      route: '/settings/automations',
      icon: <IconTableShortcut width="20" height="20" color={XelaColor.Blue5} />,
      badge: 'beta',
    })
  }

  if (auth.role.permissions.includes('settings_module_access_security')) {
    tabItems.push({
      label: 'Security',
      route: '/settings/security',
      icon: <IconScanEye width="20" height="20" color={XelaColor.Blue5} />,
      badge: 'beta',
    })
  }

  return (
    <motion.div
      className={`${globalStore.isSubSidebarOpen ? 'w-72 block' : 'hidden'} z-10 absolute bg-white min-h-screen top-0`}
      initial={{ opacity: 0.5, x: 0 }}
      animate={{ opacity: 1, x: globalStore.isSidebarOpen ? 225 : 65 }}
      transition={{ duration: 0.25 }}
    >
      <div>
        <VStack
          bg={XelaColor.White}
          spacing="12px"
          justifyContent="flex-start"
          height="100vh"
          className="shadow py-8 px-4 overflow-auto"
        >
          <HStack justifyContent={globalStore.isSubSidebarOpen ? 'space-between' : 'center'}>
            <VStack spacing="4px">
              <HStack justifyContent={globalStore.isSubSidebarOpen ? 'space-between' : 'center'}>
                {globalStore.isSubSidebarOpen && (
                  <VStack spacing="4px">
                    <Typography variant={'subheadline'} color={XelaColor.Gray3}>
                      Settings
                    </Typography>
                  </VStack>
                )}
              </HStack>
            </VStack>
          </HStack>
          <Divider variant="dotted" />
          <VStack spacing="8px">
            {tabItems.map((item, index) => (
              <Box
                key={index}
                onClick={() => {
                  dispatch(toggleSubSidebar(!globalStore.isSubSidebarOpen))
                  router.visit(`${item.route}`)
                }}
                style={
                  page.url === item.route
                    ? {
                        padding: '8px',
                        backgroundColor: XelaColor.Blue12,
                        borderRadius: '12px',
                        width: '100%',
                      }
                    : {
                        padding: '8px',
                      }
                }
                className="flex space-x-4 w-full px-2 py-3 cursor-pointer hover:bg-blue-12 rounded-lg"
              >
                <Center width="18px" height="18px" style={{ minWidth: '18px' }}>
                  {item.icon}
                </Center>
                <Box className="flex justify-between w-full">
                  <Typography variant="body-small-bold">{item.label}</Typography>
                  {item.badge && (
                    <Badge color="pink" size="sm" className="capitalize">
                      {item.badge}
                    </Badge>
                  )}
                </Box>
              </Box>
            ))}
          </VStack>
        </VStack>
      </div>
    </motion.div>
  )
}

export default SubSidebar
